
import React,{useState,useEffect} from 'react'
import useReportErr from '../hooks/useReportErr'
import {useGetData,useDeleteData,usePostData,usePutData} from '../hooks/useGetData'
import './SearchRecepyNew3.css'
import './RangeNew.css'
import {RiDeleteBackLine} from 'react-icons/ri'
import{AiOutlineInfoCircle} from 'react-icons/ai'
import { RotatingLines } from 'react-loader-spinner'
import Alert from '../functions/appAlert'
import YesNoAlert from '../functions/YesNoAlert'


import YesNoAlertDeleteReceipe from '../functions/YesNoAlert'
import useNutritionSummary from '../hooks/useNutritionSummary'
import {TbMeat} from 'react-icons/tb'
import {TbCheese} from 'react-icons/tb'
import{LuWheat} from 'react-icons/lu'
import {BsFire} from 'react-icons/bs'
import { BsTrash } from 'react-icons/bs';
import { IoResizeOutline } from "react-icons/io5";
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import{AiOutlinePlusCircle} from 'react-icons/ai'
import{AiOutlineMinusCircle} from 'react-icons/ai'
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import noimagefound1 from '../images/noimagefound1.jpg'
import { CiImageOn } from "react-icons/ci";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { RiUploadCloud2Line } from "react-icons/ri"
import { RiDownloadCloud2Line } from "react-icons/ri"
import { IoIosImages } from "react-icons/io"
import { IoDocumentTextOutline } from "react-icons/io5";




export default function SearchRecepyNew2() {

    const [getSearchNew,setSearchNew]=useState([{}])
    const[getRecepyCategories,setRecepyCategories]=useState([{}])
    const[chosenCategory,setChosenCategory]=useState(0)
    const[getRecepyBycategory,setRecepyBycategory]=useState([{}])
    const[RecepyData,setRecepy]=useState([{}])
    const[chosenRecepyId,setChosenRecepyId]=useState()
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getShowAlert,setShowAlert] = useState(false);
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const[psize,setpsize]=useState(1)
    const [IngredData,setIngredData]=useState([{}])
    const [getIngredCategories,setIngredCategories]=useState([{}])
    const[getIngredSupplier,setIngredSupplier]=useState([{}])
    const[getIngredNutrients,setIngredNutrients]=useState([{}])
    const[expandedRow,setExpandedRow]=useState(null)
    const[chosenIngredid,setchosenIngredid]=useState(0)
    const[showIngredComment,setshowIngredComment]=useState(false)
    const[getIngredComments,setIngredComments]=useState([{}])
    const[getnutritionData,setnutritionData]=useState([{}])
    const[getReceivedDataStatus,setReceivedDataStatus]=useState(false)
    const[chosenRecepyName,setChosenRecepyName]=useState('')
    const[showSaveRecepyButton,setshowSaveRecepyButton]=useState(false)
    const [getRecepyHeaderData,setRecepyHeaderData]=useState([{}])
    const [getNutrientSummary,setNutrientSummary]=useState([{}])
    const[getButtonSaveActive,setButtonSaveActive]=useState(false) // we toggle the button to highlight it to remind user to save
    const[sortBenaemn,setSortBenaemn]=useState(false)
    const[sortOrder,setSortOrder]=useState(false)
    const[sortKalories,setSortKalories]=useState(false)
    const[recepyComments,setRecepyComments]=useState([{}])
    const[getPortions,setPortions]=useState([{}]) // number of portions
    const[getPortionSize,setPortionSize]=useState(1) // the size of the portion
    const[getCalculatedPortion,setCalculatedPortion]=useState(1) // the calculated size of the portion
    const[getSliderValue,setSliderValue]=useState(0) // the value of the slider
    const[referenceRecepyObj,setreferenceRecepyObj]=useState([{}]) // the reference recepy object
    const[showCommentStatus,setShowCommentStatus]=useState(false) // the status of the comment
    const[getBackGroundShade,setBackGroundShade]=useState(0.45)
    const[getRenderKey,setRenderKey]=useState(0) // this is the key for the component
    const[getMenuShade,setMenuShade]=useState(0.75)
    const[getMenuShade2,setMenuShade2]=useState(0.45)
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    const[getResizeTextArea,setResizeTextArea]=useState(false)
    const [getColorVal,setColorVal]=useState('')
    const [getImageVal,setImageVal]=useState('')
    const [getImageUrl,setImageUrl]=useState('')
    const [getShowFurigana,setShowFurigana]=useState(false) // this is the show furigana
    const[getFuriganaName,setFuriganaName]=useState('') // this is the furigana name
    const [pageid]=useState(6)
    const [logtime]=useState(5000)
    const[getsearchName,setsearchName]=useState('')
    const[getTabNr,setTabNr]=useState(0) // we start with the recipe info tab


    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is the window height
    const[getCountry,setCountry]=useState('se') // this is the country
    const[getIpadIsUsed,setIpadIsUsed]=useState(false) // this is the ipad

    const [getImageText,setImageText]=useState('') // this is the image text
    const[getRecipeImage,setRecipeImage]=useState(0)
    const [recipeImages, setRecipeImages] = useState([]);
    const[getChosenImageIndex,setChosenImageIndex]=useState();
    const [getYesNoDeleteRecipe,setYesNoDeleteRecipe]=useState('')
    const[getShowYesNoDeleteRecipeAlert,setShowYesNoDeleteRecipeAlert]=useState(false)
    const[getShowAlert1,setShowAlert1]=useState(false)
    const[getOpenImageOnCSection,setOpenImageOnCSection]=useState(false)

        // file handling
        const maxSize = 3 * 1024 * 1024; // 3 Megabytes
        const acceptedFileTypes = ['image/jpeg', 'image/png'];
        const [file, setFile] = useState(null);
        const [error, setError] = useState(null);
        // end file handling
        const [trigger, setTrigger] = useState(false); 
        const[getTabId,setTabId]=useState(0)
        // range text
        const [rangeText, setRangeText] = useState({
          "-2": "25%",
          "-1": "50%",
          "0": "0",
          "1": "1",
          "2": "2",
          "3": "3",
          "4": "4",
          "5": "5",
          "6": "6",
          "7": "7"
          
        });


    useEffect(()=>{
      let country = localStorage.getItem('country')||'se' // this is the country code
      setCountry(country)
    },[])

    
    let newFontFamily = '';
    if (getCountry === 'ja') {
       newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    } else {
      newFontFamily = 'Arial, Helvetica, sans-serif'
      
    //newFontFamily = 'Times New Roman, Times, serif'
    //newFontFamily ='Zen Old Mincho, Arial, Helvetica, sans-serif'
    }
    document.documentElement.style.setProperty('--font-family', newFontFamily);



        const { nutrientSummary, calculateTotalNutrients } = useNutritionSummary(RecepyData,getIngredNutrients);
        const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
        const { imageUrl, alertMessage, alertCode, showAlert } = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

        const navigate = useNavigate();

useEffect(() => {
  function setSize() {
    setWindowWith(window.innerWidth);
    setWindowHeight(window.innerHeight);
  
    if(window.innerWidth < 600){

      setMobileIsUsed(true)
    } else{
      setMobileIsUsed(false)
    }

  }


  // Call setSize initially to set the correct state
  setSize();

  // Add the event listener
  window.addEventListener('resize', setSize);

  // Return a cleanup function to remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('resize', setSize);
  };
}, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies

// console.log(windowWidth)
// console.log(windowHeight)
//alert(windowHeight)

function rgbToRgba(rgb, alpha) {
  return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
}

//--bakcground shade settings
let rgbaVal
let rgbaVal2
let val

if(getColorVal && getColorVal.length > 0){
let rgb = getColorVal;
let alpha = getMenuShade; // replace with your alpha value
let alpha2 = getMenuShade2; // replace with your alpha value
rgbaVal = rgbToRgba(rgb, alpha);
rgbaVal2 = rgbToRgba(rgb, alpha2);
val=`rgba(255, 255, 255, ${getBackGroundShade})`

}else{
  rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
  rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
  val=`rgba(255, 255, 255, ${getBackGroundShade})`
}
//---

useEffect(() => {
  let root = document.documentElement;
  root.style.setProperty('--rgbaVal', rgbaVal);
  root.style.setProperty('--rgbaVal2', rgbaVal2);
  root.style.setProperty('--val',val);
  root.style.setProperty('--windowHeight',windowHeight+'px');

  
}, [getBackGroundShade,getMenuShade,getMenuShade2]);

    // language settings
    const selectedCountry = languages[getCountry];

     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.myrecipes?.title
    }else{
      title=selectedCountry.myrecipes?.titleMobile
    }
 

        const {
          leftSelectLegendMinaRecept,
          leftSelectFirstOption,
          aiHeader,
          listHeaderId,
          listHeaderIngrediens,
          listHeaderMangd,
          listHeaderKostnad,
          listHeaderKal,
          listHeaderOrder,
          ingredCommentBoxInfo,
          ingredInfoBoxPlus,
          ingredInfoBoxMinus,
          antalTeckenInfoText,
          addIngredientSelectLegend,
          addIngredientSelectFirstOption,
          numPortionSelectLegend,
          numPortionsSelectlegendMobile,
          portionSizeInputLegend,
          portionSizeInputLegendMobile,
          saveRecepyButtonText,
          saveRecepyButtonTextMobile,
          sizeRecepyDoublerLegend,
          nutrientEnergyLegend,
          nutrientKolhydraterLegend,
          nutrientProteinLegend,
          nutrientFettLegend,
          rightHeaderBeskrivning,
          rightBottomAntalTecken,
          titleJusteraBakgrund,
          titleDatabasensIngrediensId,
          titleKlickaforattSortera,
          titleMangdIgramPerIngrediens,
          titleKostnadPerIngrediens,
          titleKalorierPerIngrediens,
          titleKlickaForMerInformation,
          titleTaBortRecept,
          titleSummaEnergiIRecept,
          titleTotalMangdKolhydraterIRecept,
          titleTotalMangdProteinIRecept,
          titleTotalMangdFettIRecept,
          titleHamtaNaringsvarde,
          titleMinskaMangd,
          titleOkaMangd,
          textAreaKomment,
          nutrientListHeaderSubject,
          nutrientListHeaderAmount,
          nutrientListHeaderValue,
          hideNutrientValues,
          titleDeleteFromRecipe,
          footerTotalt,
          titleTotalSummary,
          portionInputText,
          titleDoublerLegend,
          addIngredientSelectLegendMobile,
          searchLegend,
          searchPlaceholdertext,
          searchButton,
          imageIconText,
          recipeIconText,
          uploadIconText,
          downloadIconText,
          deleteImageTitle,
          choseImageButtontext,
          choseImageUploadText,
          recepyMustBeChosenBeforeUploadMessage,
          createImageTextPlaceholder,
          createImageButtonText,
          generateAIimageDisclaimer,
          descriptionIconText,


        } = selectedCountry.myrecipes || {}

   // error messages
   const selectedError=AppError[localStorage.getItem('country')||'en']

   const{
    recepyCreated,
    recepyDeleted,
    yesNoDeleteRecepy,
    yesNoDeleteRecepyWithImages,
    btnYes,
    btnNo,
    recipeUpdated,
    selectedImageDeleted,
    imageUploadedMessage,
    imageDeletQuestion,
    imageSearchTextToLong,
    aiimageIsDone,
    aiimageCantBeDone,
    aiImageCantBeUploaded,
    aiSubscriptionVoid,
    imageuploadVoid,
    
    
   }=selectedCountry.confirmationMessages || {}
  


          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl]);
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);




          useEffect(()=>{

          async function GetAllIngredData(){
            try{
              let response= await useGetData('getallingredientsAndLatestPrice')
               setIngredData(response.data[0]) // we set the data here
               setIngredCategories(response.data[1]) 
               setIngredSupplier(response.data[2])
               setRecepyCategories(response.data[3])
               setIngredNutrients(response.data[4])
              
               // here i want to make a object a array number 1 to 50
                let myNewObjArr=[]
                for(let i=1;i<=100;i++){
                  myNewObjArr.push({'portion':i})
                }
                setPortions(myNewObjArr)
                setSpinnerLoading(false)

            
            }catch(error){
              setAlertCode(19001)
              setAlertMessage(selectedError[19001])
              setSpinnerLoading(false)
              setShowAlert(true)
            }
            }
          GetAllIngredData()
        },[])

        useEffect(() => {
            calculateTotalNutrients();
          }, [RecepyData, getnutritionData]);
        
        
        useEffect(()=>{
            setSpinnerLoading(true)
          async function GetDataByCategory(){
            try{
            const response=await useGetData(`recepybycategory/${chosenCategory}`)
            setRecepyBycategory(response.data[0])
            setSpinnerLoading(false)  
        }catch(error){
            setSpinnerLoading(false)
            setAlertCode(19002);
            setAlertMessage(selectedError[19002])
            setShowAlert(true);
          
        }
          }
        
        GetDataByCategory()
        },[chosenCategory])
        
        
        useEffect(()=>{
            setButtonSaveActive(false)
            setSliderValue(1)
            setSpinnerLoading(true)
            setExpandedRow(null)// here we reset the expanded row
            async function GetDataByRecepyId(){
            try{
            const response=await useGetData(`recepybyid/${chosenRecepyId}`)
            // here we will make a check and update the object
            let myNewObjArr=[...response.data[0]]
            setImageText(response.data[1] && response.data[1][0] ? response.data[1][0].benaemn : '')
            setChosenImageIndex(null)
             // here i want to sort the data by order, order i a column in the dataobject
            // we will sort the data by order ascending if there is a order greater than 1
            //if there is no order we do not sort at all.
            let orderCheck=myNewObjArr.find(data=>data.arborder>0)
            if(orderCheck!==undefined){
              myNewObjArr.sort((a,b)=>a.arborder-b.arborder)
            }
            
            
            setreferenceRecepyObj(response.data[0])
            // we will copy the nutrient object
            let myNewNutrientObjArr=[...getIngredNutrients]
            // we will loop through the object and check if the kaloriekoeff is 0 or null
            // if the kaloriekoeff is 0 or null we will try to find the kaloriekoeff in the object getIngredNutrients
            // if we find the kaloriekoeff in the object getIngredNutrients we will update the kaloriekoeff in the object RecepyData
            // if we dont find the kaloriekoeff in the object getIngredNutrients we will set the kaloriekoeff to 0
            // use map
            //this code must be changed, this mynewObjarr array must bechange to another array, now we only
            // like this
            
            myNewObjArr.map(data=>{
                if(data.kaloriekoeff===0||data.kaloriekoeff===null){
                    // we will try to find the kaloriekoeff in the object getIngredNutrients
                    let kaloriekoeff=myNewNutrientObjArr.find(data1=>data1.ingredid===data.ingredid&&data1.nutrient==='Energy')
                    // if the kaloriekoeff is undefined we will set it to 0
                    if(kaloriekoeff===undefined){
                        data.kaloriekoeff=0
                    }else{
                        // if the kaloriekoeff is not undefined we will update the kaloriekoeff in the object RecepyData
                        data.kaloriekoeff=kaloriekoeff.quantity/100
                    }
                }
            })



            // setRecepy(response.data[0])
            setRecepy(myNewObjArr)
            setRecepyHeaderData(response.data[1]) // here we set the recepy header data
            setFuriganaName(response.data[1][0]?.benaemnfuri || '') // here we set the furigana name
            setCalculatedPortion(response.data[1][0]?.portions || 1);//  here we set the size of the multiplication
            setpsize(response.data[1][0]?.psize || 1);//  here we set the size of the multiplication
           
             // here we need to manipulate the slider value
             let sliderValue=response.data[1][0]?.psize || 1

              if(sliderValue===0.5){
                setSliderValue(-1);//  here we set the size of slider to the same as the size of the multiplication
              }else if(sliderValue===0.25){
                setSliderValue(-2);//  here we set the size of slider to the same as the size of the multiplication
              }else{
            setSliderValue(response.data[1][0]?.psize || 1);//  here we set the size of slider to the same as the size of the multiplication
              }
          
          
          
            setSpinnerLoading(false)
            setReceivedDataStatus(false)
          // here we calculate the total amount of nutrients
        
           }catch(error){
            console.log(error)
            setSpinnerLoading(false)
            setShowAlert(true);
            setAlertCode(19003);
            setAlertMessage(selectedError[19003])
           
            }
            }
            GetDataByRecepyId()
        
        },[chosenRecepyId])


    async function GetRecepyComments(name){
      setSpinnerLoading(true)
        try{
            let response=await useGetData(`getrecepycomments/${name}`)
            if(response.status===200){
            setRecepyComments(response.data)
            setSpinnerLoading(false)
            }else if(response.status===201){
              setRecepyComments([])
              setSpinnerLoading(false)
       
            }
        }catch(error){
            setAlertCode(19008)
            setAlertMessage(selectedError[19008])
            setShowAlert(false)
            setSpinnerLoading(false)
       

        }
    }
  
  

 

        
        
        
        const RunReportErrHandler = async (errCode) => {
            setSpinnerLoading(true);
            await useReportErr('ErrReport',{errCode});
            setSpinnerLoading(false);
          };
        
        useEffect(() => {
            if (getAlertCode > 0) {
              RunReportErrHandler(getAlertCode);
            }
          }, [getAlertCode]);
          
          const handleCloseAlert = () => {
            setShowAlert(false);
          };
          const handleCloseAlert1 = () => {
            setShowAlert1(false);
          };

          const handleCloseAlertYes = () => {
            setShowYesNoAlert(false);
            deleteImageHandler()

          };
          const handleCloseAlertNo = () => {
            setShowYesNoAlert(false);
         
          };

          const handleCloseAlertNoImage = () => {
         
            setShowYesNoDeleteRecipeAlert(false)
          };


          
const categoryHandler=(value)=>{
    setChosenCategory(value)
    setOpenImageOnCSection(false)
}

const showRecepyHandler=(value)=>{
  setFuriganaName('')
  setShowFurigana(false)
  setPortionSize(1)
  setCalculatedPortion(1)
    setChosenRecepyId(value)
    setShowCommentStatus(true)
    setOpenImageOnCSection(true)
    //here we wnt to set the recepy name
    let recepyIndex=getRecepyBycategory.findIndex(data=>data.receptid===value)
    let recepyName=getRecepyBycategory[recepyIndex].benaemn
    setChosenRecepyName(recepyName)
    GetRecepyComments(recepyName)
}

const handleRowClickIndex = (index,ingredid) => {// we get the index of the row and the ingredid
  setchosenIngredid(ingredid) // here we set the ingredid to our state
    setReceivedDataStatus(false) // here we reset the received data status
    setnutritionData([]) // here we reset the nutrition data
    setshowIngredComment(true) // here we open the component

    if (expandedRow === index) { // here we check if the index is the same as the expanded row
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  // useEffect(()=>{
  //   const timer = setTimeout(() => {
  //     setShowCommentStatus(false)
  //   }, 10000);
  // },[showCommentStatus])

  const ingredCommentsHandler=(comment)=>{
    setButtonSaveActive(true)
    //here we will copy the array RecepyData and add the comment to the ingredient
    let myNewObjArr=[...RecepyData]
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)

    // we will add the comment to the ingredient
    myNewObjArr[newIndex].btext=comment
    // //we will set the new array to our state
    setRecepy(myNewObjArr)
  
   
  }
  const deleteIngredientHandler=(ingredid)=>{ // delete ingredient from recepy
    //here we will copy the array RecepyData and delete the ingredient
    setSpinnerLoading(true)
    let myNewObjArr=[...RecepyData]
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===ingredid)
    // we will delete the ingredient from the array
    myNewObjArr.splice(newIndex,1)

    setSpinnerLoading(true)
     async function DeleteIngredient(ingredid){
        try{
           let response= await useDeleteData(`deleteingredient/${ingredid}/${chosenRecepyId}`)
         
            if(response.status===200){
            setSpinnerLoading(false)
            setAlertCode(0)
            setAlertMessage(recipeUpdated)
            setShowAlert(true)
            }
        }catch(error){
            setAlertCode(19004)
            setAlertMessage(selectedError[19004])
            setShowAlert(true)
            setSpinnerLoading(false)
        }
  }
    DeleteIngredient(ingredid)
    //we will set the new array to our state
    setRecepy(myNewObjArr)
    setSpinnerLoading(false)

    }






const changeAmountHandler=(amount)=>{
    setButtonSaveActive(true)
  if(amount===1){
  // here i want to change the amount of the ingredient i want to decrease by 1
  //copy recepydata
  let myNewObjArr=[...RecepyData]

  //we will find the index of the ingredient we want to change
  let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  // fist find the amount of the ingredient
  let amountInGram=myNewObjArr[newIndex].mangd
  // we will decrease the amount by 1
  myNewObjArr[newIndex].mangd=amountInGram-1
  // we will change the totalcost
  myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram-1)/1000
  // here we also need to change the amount of calories
  // first we find the total amount of calories
  let totalCalories=myNewObjArr[newIndex].kalories
  // to find the number of calories per amount of grams we divide the total calories with the amount of grams
  let caloriesPerGram=totalCalories/amountInGram
  // we will multiply the calories per gram with the new amount of grams
  myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram-1)

  //here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
//if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
   
    // here we need to find the kalories in the object getIngredNutrients
    totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
    // if the kaltories is undefined we will set the kalories to 0
    if(totalamountOfKalories===undefined){
        totalamountOfKalories=null
    }else{
    // if the kalories is not undefined we will divide by 100 to get the kalories per gram
    totalamountOfKalories=totalamountOfKalories.quantity/100
   
    }
// we will now multiply the total amount of kalories with the amount of grams
totalamountOfKalories=totalamountOfKalories*amountInGram
myNewObjArr[newIndex].kalories=totalamountOfKalories
}
  //we will set the new array to our state
  setRecepy(myNewObjArr)


  }else if(amount===2){
    // here i want to change the amount of the ingredient i want to increase by 1
  //copy recepydata
  let myNewObjArr=[...RecepyData]
  //we will find the index of the ingredient we want to change
  let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  // fist find the amount of the ingredient
  let amountInGram=myNewObjArr[newIndex].mangd
  // we will increase the amount by 1
  myNewObjArr[newIndex].mangd=amountInGram+1
  // we will change the totalcost
  myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram+1)/1000
  // here we also need to change the amount of calories
  // first we find the total amount of calories
  let totalCalories=myNewObjArr[newIndex].kalories
  // to find the number of calories per amount of grams we divide the total calories with the amount of grams
  let caloriesPerGram=totalCalories/amountInGram
  // we will multiply the calories per gram with the new amount of grams
  myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram+1)

  // here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
//if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
    // here we need to find the kalories in the object getIngredNutrients
    totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
    // if the kaltories is undefined we will set the kalories to 0
    if(totalamountOfKalories===undefined){
        totalamountOfKalories=null
    }else{
    // if the kalories is not undefined we will divide by 100 to get the kalories per gram
    totalamountOfKalories=totalamountOfKalories.quantity/100
  
    }
// we will now multiply the total amount of kalories with the amount of grams
totalamountOfKalories=totalamountOfKalories*amountInGram
//here we will make the  amount to integer by using not parseint we use math.round
myNewObjArr[newIndex].kalories=totalamountOfKalories
}

  //we will set the new array to our state
  setRecepy(myNewObjArr)
  
  }else if(amount===3){
      // here i want to change the amount of the ingredient i want to decrease by 1
  //copy recepydata
  let myNewObjArr=[...RecepyData]
 
  //we will find the index of the ingredient we want to change
  let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  // fist find the amount of the ingredient
  let amountInGram=myNewObjArr[newIndex].mangd
  // we will decrease the amount by 1
  myNewObjArr[newIndex].mangd=amountInGram-10
  // we will change the totalcost
  myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram-10)/1000
  // here we also need to change the amount of calories
  // first we find the total amount of calories
  let totalCalories=myNewObjArr[newIndex].kalories
  // to find the number of calories per amount of grams we divide the total calories with the amount of grams
  let caloriesPerGram=totalCalories/amountInGram
  // we will multiply the calories per gram with the new amount of grams
  myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram-10)

  //here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
//if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
   
    // here we need to find the kalories in the object getIngredNutrients
    totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
    // if the kaltories is undefined we will set the kalories to 0
    if(totalamountOfKalories===undefined){
        totalamountOfKalories=null
    }else{
    // if the kalories is not undefined we will divide by 100 to get the kalories per gram
    totalamountOfKalories=totalamountOfKalories.quantity/100
   
    }
// we will now multiply the total amount of kalories with the amount of grams
totalamountOfKalories=totalamountOfKalories*amountInGram
myNewObjArr[newIndex].kalories=totalamountOfKalories
}
  //we will set the new array to our state
  setRecepy(myNewObjArr)

  }else if(amount===4){
        // here i want to change the amount of the ingredient i want to increase by 1
  //copy recepydata
  let myNewObjArr=[...RecepyData]
  //we will find the index of the ingredient we want to change
  let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  // fist find the amount of the ingredient
  let amountInGram=myNewObjArr[newIndex].mangd
  // we will increase the amount by 1
  myNewObjArr[newIndex].mangd=amountInGram+10
  // we will change the totalcost
  myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram+10)/1000
  // here we also need to change the amount of calories
  // first we find the total amount of calories
  let totalCalories=myNewObjArr[newIndex].kalories
  // to find the number of calories per amount of grams we divide the total calories with the amount of grams
  let caloriesPerGram=totalCalories/amountInGram
  // we will multiply the calories per gram with the new amount of grams
  myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram+10)

  // here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
//if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
    // here we need to find the kalories in the object getIngredNutrients
    totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
    // if the kaltories is undefined we will set the kalories to 0
    if(totalamountOfKalories===undefined){
        totalamountOfKalories=null
    }else{
    // if the kalories is not undefined we will divide by 100 to get the kalories per gram
    totalamountOfKalories=totalamountOfKalories.quantity/100
    
    }
// we will now multiply the total amount of kalories with the amount of grams
totalamountOfKalories=totalamountOfKalories*amountInGram
//here we will make the  amount to integer by using not parseint we use math.round
myNewObjArr[newIndex].kalories=totalamountOfKalories
}

  //we will set the new array to our state
  setRecepy(myNewObjArr)
  }
}



  

const GetFoodInfoContainer=()=>{
  setSpinnerLoading(true)
  setButtonSaveActive(true)

  // here we check if we have data in the object getIngredNutrients
  // if we have data in this object we will set the state getnutritionData to the data in this object
  let checkIfWeHaveData=getIngredNutrients.filter(data=>data.ingredid===chosenIngredid)

  //if(checkIfWeHaveData.length>0){
  if  (Array.isArray(checkIfWeHaveData) && checkIfWeHaveData.length > 0){
    
    //here we have to toggle the received data status to true
    if(getReceivedDataStatus===true){
      setnutritionData([])
      setReceivedDataStatus(false)
      setSpinnerLoading(false)
    }else{
    setnutritionData(checkIfWeHaveData)
    //we have data so we update the object RecepyData
    //here we need to update the amount of kalorikoefficient
    // find the index of the ingredid
    // copy object RecepyData
    let myNewObjArr=[...RecepyData]
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
    // we will change the amount kaloriekoeff
    myNewObjArr[newIndex].kaloriekoeff=checkIfWeHaveData.find(data=>data.nutrient==='Energy').quantity/100
    //we will set the new array to our state
   
    setRecepy(myNewObjArr)
    
    setReceivedDataStatus(true)
   
     setSpinnerLoading(false)
    }
  
  
  }else{
    // here we need to make a request to the api
    GetFoodInfoHandler()
  }
  }
  
  async function GetFoodInfoHandler(){

  //copy data
  let myNewObjArr=[...RecepyData]
  //we will find the index of the ingredient we want to change
  let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  //find the name of the ingredient
  let benaemn=myNewObjArr[newIndex].benaemn
  let textToTranslate=benaemn
  let language='en'
  
  // here i need to make a request for translation
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`translate/${language}/${textToTranslate}/${chosenIngredid}`)
    let amountofCalories=response.data.calories
    let totalDailyData=response.data.totalNutrients
    // console.log(response.data.totalNutrients)
    const totalDailyArray = Object.keys(totalDailyData).map((key) => ({
      nutrient: totalDailyData[key].label,
      quantity: totalDailyData[key].quantity,
      unit: totalDailyData[key].unit,
    }));
   setnutritionData(totalDailyArray)
    
    // here we need to check if the amount of calories is undefined
    // this function translates the ingredientname to english but also it check the amount of calories
    //and the calories are the most important so we will check if the amount of calories is a number
    if(isNaN(amountofCalories)){
      setAlertCode(19009)
      setAlertMessage(selectedError[19009])
      setShowAlert(true)
      setSpinnerLoading(false)
      return
    }else{
        //here we want to use the data we already got from backend in the totalDailyArray
        // we will find the kalories in the array
        let kalories=totalDailyArray.find(data=>data.nutrient==='Energy')
        //we will divide with 100 to get the kalories per gram
        kalories=kalories.quantity/100
        // we will update the kaloriekoeff value in the object RecepyData
        myNewObjArr[newIndex].kaloriekoeff=kalories
        // we will update the kalories value in the object RecepyData
        //update state
        setRecepy(myNewObjArr)
    setReceivedDataStatus(true)
    setSpinnerLoading(false)
    }
  
  
  }catch(error){
    setAlertCode(19005)
    setAlertMessage(selectedError[19005])
    setShowAlert(true)
    setSpinnerLoading(false)
  }
  }


  const addDbOwnerIngredient=(ingredid)=>{
    setButtonSaveActive(true)
    setchosenIngredid(ingredid)
    // here i want to add a new ingredient to the object RecepyData
    //copy recepydata
    let myNewObjArr=[...RecepyData]
    //we will add the new ingredient to the array
    // we have to find the benaemn from the IngredData object
    let benaemn=IngredData.find(data=>data.ingredid==ingredid)
    // we ned to find receptid
    let receptid=myNewObjArr[0].receptid
    
  
    // here we must find the pris from the IngredData object
    let pris=IngredData.find(data=>data.ingredid==ingredid)
    //here we have to find te calories for the ingredient in the object getIngredNutrients
    // and to find the kalories we need to look on the nutrient column and look for 'Energy'
    let kalories=getIngredNutrients.find(data=>data.ingredid==ingredid&&data.nutrient==='Energy')
    // when we have the kalories we need to divide by 100 to get the kalories per gram
    //first we need to check so the kalories is not undefined
    let kaloriekoeff
    if(kalories===undefined){
        kaloriekoeff=0
    }else{
    kaloriekoeff=kalories.quantity/100
    kaloriekoeff=parseFloat(kaloriekoeff.toFixed(2))
    }

    // before we push we must check if this ingredient already are in the array
    let checkIfIngredidExist=myNewObjArr.find(data=>data.ingredid==ingredid)
    if(checkIfIngredidExist){
        return
    }else{
    myNewObjArr.push({ingredid:parseInt(ingredid),benaemn:benaemn.benaemn,latest_pris:pris.pris,kalories:0,mangd:1,ADsum:0,btext:'',quantity:0,receptid:receptid,arborder:0,kaloriekoeff:kaloriekoeff})
    //we will set the new array to our state

      AddnewIngredientToRecepy(kaloriekoeff,ingredid)
    setRecepy(myNewObjArr)
    }

  }

  async function AddnewIngredientToRecepy(kaloriekoeff,ingredid){
 
    setSpinnerLoading(true)
    try{
        let response =await usePostData(`addnewingredientToRecepy`,{chosenRecepyId,ingredid,kaloriekoeff})
       // let response =await usePostData(`addnewingredientToRecepy/${chosenRecepyId}/${ingredid}/${kaloriekoeff}`)
      
        if(response.status===200){
            setSpinnerLoading(false)
        }
    }catch(error){
        setAlertCode(19006)
        setAlertMessage(selectedError[19006])
        setShowAlert(true)
        setSpinnerLoading(false)
   
    }
  }
  
// here we wneed to sort the categories ascending by name
let sortedCategories=getRecepyCategories.sort((a,b)=>{
    if(a.benaemn<b.benaemn){
        return -1
    }else if(a.benaemn>b.benaemn){
        return 1
    }
    return 0
})



const descriptionHandler=(description)=>{
    setButtonSaveActive(true)
  let myNewObjArr=[...getRecepyHeaderData]
  myNewObjArr[0].btext=description
setRecepyHeaderData(myNewObjArr)
  setshowSaveRecepyButton(true)
}


function CalculateTotalNutrients(){
  //here we copy the array RecepyData
  let myNewObjArr=[...RecepyData]
  // here we need to calculate the total amount of nutrients
  //nutrient data we have in teh array getnutritionData and the key ingredid
  // we copy the array getnutritionData
  let myNewObjArr2=[...getnutritionData]
  // we have the array of ingredients in the array RecepyData
  // we have the array of nutrients in the array getnutritionData
  // in the nutrient data we target 3 values, nutrient, quantity and unit
  //for the amount of kcals we look for the value in the nutrient column and we look for 'Energy
  //for the amount of carbohydrates we look for the value in the nutrient column and we look for 'Carbohydrates (net)'
  //for the amount of protein we look for the value in the nutrient column and we look for 'Protein'
  //for the amount of fat we look for the value in the nutrient column and we look for 'Total lipid (fat)'
  let totalKcals=0
  let totalCarbohydrates=0
  let totalProtein=0
  let totalFat=0
  // now we map over the array RecepyData
  myNewObjArr.map((data,index)=>{
  //for the first ingredid we will find the amount of kcals and look for 'Energy'
  let amountOfKcals=myNewObjArr2.find(data2=>data2.ingredid===data.ingredid&&data2.nutrient==='Energy')
      amountOfKcals=amountOfKcals.quantity
      // we will now divide the amount of kcals with 100 to get the amount of kcals per gram
      amountOfKcals=amountOfKcals/100
      // we will multiply the amount of kcals per gram with the amount of grams
      amountOfKcals=amountOfKcals*data.mangd
      // we will add the amount of kcals to the total amount of kcals
      totalKcals=totalKcals+amountOfKcals
      // no decimals

      //for the first ingredid we will find the amount of carbohydrates and look for 'Carbohydrates (net)'
      let amountOfCarbohydrates=myNewObjArr2.find(data2=>data2.ingredid===data.ingredid&&data2.nutrient==='Carbohydrate, by difference')
      amountOfCarbohydrates=amountOfCarbohydrates.quantity
      // we will now divide the amount of carbohydrates with 100 to get the amount of carbohydrates per gram
      amountOfCarbohydrates=amountOfCarbohydrates/100
      // we will multiply the amount of carbohydrates per gram with the amount of grams
      amountOfCarbohydrates=amountOfCarbohydrates*data.mangd
      // we will add the amount of carbohydrates to the total amount of carbohydrates
      totalCarbohydrates=totalCarbohydrates+amountOfCarbohydrates
      // no decimals

      //for the first ingredid we will find the amount of protein and look for 'Protein'
      let amountOfProtein=myNewObjArr2.find(data2=>data2.ingredid===data.ingredid&&data2.nutrient==='Protein')
      amountOfProtein=amountOfProtein.quantity
      // we will now divide the amount of protein with 100 to get the amount of protein per gram
      amountOfProtein=amountOfProtein/100
      // we will multiply the amount of protein per gram with the amount of grams
      amountOfProtein=amountOfProtein*data.mangd
      // we will add the amount of protein to the total amount of protein
      totalProtein=totalProtein+amountOfProtein
      // no decimals
     //for the first ingredid we will find the amount of fat and look for 'Total lipid (fat)'
      let amountOfFat=myNewObjArr2.find(data2=>data2.ingredid===data.ingredid&&data2.nutrient==='Total lipid (fat)')
      amountOfFat=amountOfFat.quantity
      // we will now divide the amount of fat with 100 to get the amount of fat per gram
      amountOfFat=amountOfFat/100
      // we will multiply the amount of fat per gram with the amount of grams
      amountOfFat=amountOfFat*data.mangd
      // we will add the amount of fat to the total amount of fat
      totalFat=totalFat+amountOfFat
      // no decimals

      //this will act as nutrient headerdata so we will make a new object
      let myNewObjArr3=[] // empty array
      myNewObjArr3.push({totalKcals:totalKcals,totalCarbohydrates:totalCarbohydrates,totalProtein:totalProtein,totalFat:totalFat})
      setNutrientSummary(myNewObjArr3)

  })

}

const SaveRecepyHandler=()=>{
  setShowFurigana(false) // here we close the furigana field
  setFuriganaName('') // here we reset the furigana name 
  setButtonSaveActive(false)
  setSpinnerLoading(true)
       

  

    async function SaveRecepy(){


let myNewObjArr=[...RecepyData]
let myNewObjArr2=[...getRecepyHeaderData]

let chosenRecepyId=myNewObjArr[0].receptid



// Send the data directly without encoding
let recepyData = JSON.stringify(myNewObjArr);
let recepyHeaderData = JSON.stringify(myNewObjArr2);
setSpinnerLoading(true)



        try{
            let response= await usePostData(`saveRecepy`,{recepyData,recepyHeaderData,chosenRecepyId,psize,getFuriganaName})
         //   let response= await useGetData(`saveRecepy/${decodedRecepyData}/${decodedRecepyHeaderData}/${chosenRecepyId}/${psize}`)
            
            if(response.status===200){
                setAlertCode(0)
                setAlertMessage(recepyCreated)
                setShowAlert(false)
                setSpinnerLoading(false)
             

             

            }
        
        }catch(error){
            setAlertCode(19007)
            setAlertMessage(selectedError[19007])
            setShowAlert(true)
            setSpinnerLoading(false)
          
        }
    
    }
    SaveRecepy()
}

const  HandleArbOrderClick=async(index)=> {
    setButtonSaveActive(true)
    setSpinnerLoading(true)
      // Make a shallow copy of the mydataObj array
      const updatedData = [...RecepyData];
      // Toggle the clicked state of the arbOrder button for the clicked row
      updatedData[index].arbOrderclicked = !updatedData[index].arbOrderclicked;
    // find the highest number in the array
      let maxArbOrder = Math.max.apply(Math, updatedData.map((data) => data.arborder));
        
       if(maxArbOrder>0){
              if(updatedData.length>maxArbOrder)
              {
                updatedData[index].arborder=maxArbOrder+1
              }
              else{   
                updatedData.map((data) =>{return data.arborder=0})
                  }
    
       }else{
        updatedData[index].arborder=maxArbOrder+1
      
      }
        // Update the state with the new array of dataObj
        setRecepy(updatedData);
        
        setSpinnerLoading(false)
    }


    const SortBenaemnHandler=()=>{
        //here we will sort the recepy by benaemn
        // we will copy the array RecepyData
        let myNewObjArr=[...RecepyData]
        // we will sort the array by benaemn ascending then toggle ascending and descending
        if(sortBenaemn===false){
            myNewObjArr.sort((a,b)=>{
                if(a.benaemn<b.benaemn){
                    return -1
                }else if(a.benaemn>b.benaemn){
                    return 1
                }
                return 0
            })
            setRecepy(myNewObjArr)
            setSortBenaemn(true)
    }
    else{
        myNewObjArr.sort((a,b)=>{
            if(a.benaemn>b.benaemn){
                return -1
            }else if(a.benaemn<b.benaemn){
                return 1
            }
            return 0
        })
        setRecepy(myNewObjArr)
        setSortBenaemn(false)
    }
    }

const SortOrderHandler=()=>{
    //here we will toggle the sort order
    // we will copy the array RecepyData
    let myNewObjArr=[...RecepyData]
    // we will sort the array by arborder ascending then toggle ascending and descending
    if(sortOrder===false){
        myNewObjArr.sort((a,b)=>{
            if(a.arborder<b.arborder){
                return -1
            }else if(a.arborder>b.arborder){
                return 1
            }
            return 0
        })
        setRecepy(myNewObjArr)
        setSortOrder(true)

}else{
    myNewObjArr.sort((a,b)=>{
        if(a.arborder>b.arborder){
            return -1
        }else if(a.arborder<b.arborder){
            return 1
        }
        return 0
    })
    setRecepy(myNewObjArr)
    setSortOrder(false)

}
}

const SortKalories=()=>{
    //here we will sort ascending descending by kalories
    // we will copy the array RecepyData
    let myNewObjArr=[...RecepyData]
    // we will sort the array by kalories ascending then toggle ascending and descending
    if(sortKalories===false){
        myNewObjArr.sort((a,b)=>{
            if(a.kaloriekoeff*a.mangd<b.kaloriekoeff*b.mangd){
                return -1
            }else if(a.kaloriekoeff*a.mangd>b.kalorikoeff*b.mangd){
                return 1
            }
            return 0
        })
        setRecepy(myNewObjArr)
        setSortKalories(true)

}
else{
    myNewObjArr.sort((a,b)=>{
        if(a.kaloriekoeff*a.mangd>b.kaloriekoeff*b.mangd){
            return -1
        }else if(a.kaloriekoeff*a.mangd<b.kaloriekoeff*b.mangd){
            return 1
        }
        return 0
    })
    setRecepy(myNewObjArr)
    setSortKalories(false)

}
}

const ChangeRecepyNameHandler=(name)=>{
  // for the japanese furigana we will add another inputfiled so we set the state showFurigana to true
  setShowFurigana(true) 
    //we copy the object getRecepyHeaderData
    let myNewObjArr=[...getRecepyHeaderData]
    // we change the name
    myNewObjArr[0].benaemn=name
    // we set the new object to our state
    setRecepyHeaderData(myNewObjArr)
    setButtonSaveActive(true)
    //here we also want to change the name in the object getRecepyBycategory
    // we copy the object getRecepyBycategory, then we need to find the index of the chosenRecepyId
    let myNewObjArr2=[...getRecepyBycategory]
    let newIndex=myNewObjArr2.findIndex(data=>data.receptid===chosenRecepyId)
    // we change the name
    myNewObjArr2[newIndex].benaemn=name
    // we set the new object to our state
    setRecepyBycategory(myNewObjArr2)
    
}
const deleteRecepyHandlerContainer1=()=>{
  // here we need to show 2 different messages depending if we have images in the recipe
  if (Array.isArray(recipeImages) && recipeImages.length > 0) {
    setYesNoDeleteRecipe(yesNoDeleteRecepyWithImages);
  } else {
    setYesNoDeleteRecipe(yesNoDeleteRecepy);
  }
     
  setShowYesNoDeleteRecipeAlert(true)
}
// const deleteRecepyHandlerContainer=()=>{
//  setYesNoAlertMessage(yesNoDeleteRecepy)
// setShowYesNoAlert(true)
// }


const deleteRecepyHandler=()=>{
  setShowYesNoDeleteRecipeAlert(false)
    async function DeleteRecepy(){
      setSpinnerLoading(true)
   
      try{
        let response=await useDeleteData(`deleteRecepy/${chosenRecepyId}`)
        if(response.status===200){
          setSpinnerLoading(false)
        // when we receive status 200 we will also update the state getRecepyBycategory
        // we will delete the recepyinfo from the array getRecepyBycategory
        let myNewObjArr=[...getRecepyBycategory]
        let newIndex=myNewObjArr.findIndex(data=>data.receptid===chosenRecepyId)
        myNewObjArr.splice(newIndex,1)
        setRecepyBycategory(myNewObjArr)
        setChosenRecepyId(0)
        setChosenRecepyName('')
        setRecepy([])
        setAlertCode(0)
        setAlertMessage(recepyDeleted)
        setShowAlert1(true)
  
  
  
        }
      }catch(error){
        setAlertCode(19010)
        setAlertMessage(selectedError[19010])
        setShowAlert(true)
        setSpinnerLoading(false)
        return
      }
    
    }
    DeleteRecepy()

}

const sizeOfPortionHandler=(size)=>{
  setButtonSaveActive(true)
  setPortionSize(size)
  // here we will calculate the number of the recipe
  let totalAmountOfReceipe=RecepyData.reduce((a,b)=>a+b.mangd,0)
  // if the total amount is undefined we will set it to 0
  if(isNaN(totalAmountOfReceipe)){
    totalAmountOfReceipe=0
  }
  //here we will add psize to the total amount of receipe
  totalAmountOfReceipe=totalAmountOfReceipe*psize
  
  // we will divide the total amount of receipe with the size of the portion
  let amountOfPortions=(totalAmountOfReceipe/size)
 
  if(amountOfPortions===Infinity){

    amountOfPortions=1

  }


  let myNewObjArr3=[...getRecepyHeaderData]
  amountOfPortions=parseInt(amountOfPortions)
  myNewObjArr3[0].portions=amountOfPortions
  myNewObjArr3[0].portionSize=size

  setRecepyHeaderData(myNewObjArr3)
  setCalculatedPortion(amountOfPortions)

  
}


const NumPortionHandler=(num)=>{
  //selectboxen
  
let totalAmountOfReceipe=RecepyData.reduce((a,b)=>a+b.mangd,0)
// if the total amount is undefined we will set it to 0
if(isNaN(totalAmountOfReceipe)){
  totalAmountOfReceipe=0
}
//here we will add psize to the total amount of receipe
totalAmountOfReceipe=totalAmountOfReceipe*psize
// here we will calculate the size of the portion
let sizeOfPortion=totalAmountOfReceipe/num
setPortionSize(parseInt(sizeOfPortion))
setCalculatedPortion(num)
setButtonSaveActive(true)
//update state copy obj
sizeOfPortion=parseInt(sizeOfPortion)
let myNewObjArr=[...getRecepyHeaderData]
myNewObjArr[0].portionSize=sizeOfPortion
myNewObjArr[0].portions=num
setRecepyHeaderData(myNewObjArr)

}


const receptSizeHandler=(size)=>{
  let psize; // declare psize with let
  setSliderValue(size)
  if (size < 0) {
    // handle negative values
    psize = 1 / Math.pow(2, Math.abs(size));
  } else {
    // handle positive values
    psize = size;
  }
  setpsize(psize)
  setButtonSaveActive(true)
}



// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(19011)
      setAlertMessage(selectedError[19011])
      setSpinnerLoading(false)
      setShowAlert(true)

      return;
    }

}

async function SetmenuColorHandler(val){
  setMenuShade(val)
  setMenuShade2(val-0.20)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateMenuShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }

}catch(error){
  setAlertCode(19012)
  setAlertMessage(selectedError[19012])
  setSpinnerLoading(false)
  setShowAlert(true)

  return;
}
}

const setResizeTextAreaHandler=()=>{
  if(getResizeTextArea===false){
    setResizeTextArea(true)
  }else{
    setResizeTextArea(false)
  }
  AreaChoice()
}

function AreaChoice(){
  if(getResizeTextArea===true){
    return "xjtotalArea2"
}else if(getResizeTextArea===false&&getBackGroundShade==1){
  return "xjtotalArea1"
}else if(getResizeTextArea===false&&getBackGroundShade<1){
  return "xjtotalArea"
}
}

const resetAiCommentHandler=()=>{
  setShowCommentStatus(false)
}

const TranslateInfoHandler= async()=>{
  setSpinnerLoading(true)

  let myNewObjArr2=[...getRecepyHeaderData]
  let recepyHeaderData = myNewObjArr2[0].btext
  // i want to stringify
  recepyHeaderData=JSON.stringify(recepyHeaderData)
  let language='en'
  try{
    let response=await usePostData(`translateInfoTemporary`,{language,recepyHeaderData,chosenRecepyId})
    let responseContent = JSON.parse(response.data.data.content);
    
    let { translatedtext } = responseContent;
    
    // we make the text to a list
     let steps = translatedtext.split(/(?=\d+\. )/g).join("\n");
    let myNewObjArr=[...getRecepyHeaderData]
     myNewObjArr[0].btext=steps

    setRecepyHeaderData(myNewObjArr)
    setSpinnerLoading(false)
    setButtonSaveActive(true)
  }catch(error){
    setAlertCode(19013)
    setAlertMessage('Kan inte översätta text')
    setSpinnerLoading(false)
    setShowAlert(true)
    // setTimeout(() => {
    //   navigate('/Logout');
    // },logtime);
    return;
  }

}

const setFuriganaNameHandler=(name)=>{
  setButtonSaveActive(true)
setFuriganaName(name)
}

const searchNameHandler=(search)=>{
  setsearchName(search)
}

async function SearchRecipeHandler(){
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`searchRecepy/${getsearchName}`)
    setRecepyBycategory(response.data[0])
    setSpinnerLoading(false)
  }catch(error){
    setAlertCode(19014)
    setAlertMessage(selectedError[19014])
    setSpinnerLoading(false)
    setShowAlert(true)
    return;
  }
}

const clearSearchNameHandler=()=>{
  setsearchName('')
}




function showTab(tabId,tabNr) {
  setTabId(tabId)
  setTabNr(tabNr)
  if(tabNr===5){
    setOpenImageOnCSection(true)
  }else if(tabNr===1){
    setOpenImageOnCSection(false)
  }
  
  //here we want to make a request to the api to get the image
  const tabContents = document.querySelectorAll('.tab-content');
  tabContents.forEach(tabContent => {
    tabContent.style.display = 'none';
  });
  document.getElementById(tabId).style.display = 'block';
}

const deleteImageHandler=()=>{


  // here we delete the image from the object first
  let images=[...recipeImages]
  images.splice(getChosenImageIndex,1)

  setRecipeImages(images)
  // we call up the delete image function
 DeleteImage()

}


useEffect(()=>{
  GetImageData()
  },[chosenRecepyId,trigger])


  async function GetImageData(){
    setSpinnerLoading(true)
    try{
  const token = localStorage.getItem('auth_token');
  const headers = token ? { authorization: `Bearer ${token}` } : {};  
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamBucketImages/${chosenRecepyId}`, { headers });
if(response.status === 200){
    const images = await response.json(); // assuming the images are Base64 encoded strings

    const imageBlobs = images.map(image => {
        const byteCharacters = atob(image);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: 'image/jpeg'}); // adjust the MIME type as needed
    });
   // const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
    const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
    let loadedImagesCount = 0;

    // here we check if we have images
    //if we have we chose the image tab, if not we choose the info tab, here image
    // is info and info is image.
    if (imageUrls.length === 0) {
      setSpinnerLoading(false);
      showTab('image');
      setTabNr(2);
  } else {
imageUrls.forEach((url, index) => {
    const img = new Image();
    img.onload = () => {
        loadedImagesCount++;
        if (loadedImagesCount === imageUrls.length) {
            setSpinnerLoading(false);
              showTab('image')
              setTabNr(1)
        }
    };
    img.src = url;
});
  }

setRecipeImages(imageUrls);
    // setRecipeImages(imageUrls);
    // setSpinnerLoading(false);
} else if (response.status === 404){
    setAlertCode(0);
    setAlertMessage(selectedError[28013]);
    setSpinnerLoading(false);
    setShowAlert(false);
}
    } catch(error){
      console.log(error)
      setAlertCode(19015)
      setAlertMessage(selectedError[19015])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
  }

async function DeleteImage(){
  setSpinnerLoading(true)
  try{
    let response=await useDeleteData(`deleteimages/${chosenRecepyId}/${getChosenImageIndex}`)
    if(response.status===200){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(selectedImageDeleted)
    setShowAlert(true)
    }
  }
   catch(error){
      setAlertCode(19016)
      setAlertMessage(selectedError[19016])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
}

const handleFileChange = (e) => {
  
  let selected = e.target.files[0];

  if (selected) {
    if (selected.size > maxSize) {
      setFile(null);
      setError('File too large. Maximum size is 3MB.');
    } else if (!acceptedFileTypes.includes(selected.type)) {
      setFile(null);
      setError('Invalid file type. Only JPEG and PNG files are accepted.');
    } else {
      setFile(selected);
      setError('');
    }
  }

}

const uploadImages = async () => {
setSpinnerLoading(true);
const formData = new FormData();
formData.append('file', file);
formData.append('chosenRecepyId', chosenRecepyId);

const token = localStorage.getItem('auth_token');
const headers = token ? { 
'Authorization': `Bearer ${token}`
} : {};  

try {
//  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimage`, { 
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimage`, { 
    
    method: 'POST',
    headers: headers,
    body: formData
  });
  if (response.status === 200) {
    setSpinnerLoading(false);
    setAlertCode(0)
    setAlertMessage(imageUploadedMessage)
    setShowAlert(true)
    setTrigger(!trigger); 
  }
  else if(response.status===201){
    setAlertCode(0)
    setAlertMessage(imageuploadVoid)
    setShowAlert(true)
    setSpinnerLoading(false)
  }

} catch (error) {
   setAlertCode(19017)
    setAlertMessage(selectedError[19017])
    setSpinnerLoading(false)
    setShowAlert(true)
}
};

const choseImageHandler=(image)=>{
  setChosenImageIndex(image)
}

const deleteImageHandlerContainer=()=>{
  setYesNoAlertMessage(`${imageDeletQuestion}?`)
  setShowYesNoAlert(true)
}


const setImageTextHandler=(text)=>{
  if(text.length>700){
    setAlertCode(0)
    setAlertMessage(imageSearchTextToLong)
    setShowAlert(true)
  }else{
    setImageText(text)
  }

}

async function GenerateaiImage(){
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`generateaiimage/${chosenRecepyId}/${getImageText}`)
    if(response.status===200){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(aiimageIsDone)
    setShowAlert(false)
    setSpinnerLoading(false)
    setTrigger(!trigger); 
    }else if(response.status===201){

      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===202){
      console.log(response.status)
      setAlertCode(0)
      setAlertMessage(aiImageCantBeUploaded)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===203){
      console.log(response.status)
      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }

  }catch (error){
    setAlertCode(19018)
    setAlertMessage(selectedError[19018])
    setSpinnerLoading(false)
    setShowAlert(true)
  }
}

function ClassPicker(){
  if(getMobileIsUsed===true){
    return "xjImageContainer"
  }else if(getMobileIsUsed===false && recipeImages.length===1){
    return "xjImageContainerOneItem"
}else if(getMobileIsUsed===false && recipeImages.length===2){
  return "xjImageContainerTwoItems"
}else if(getMobileIsUsed===false && recipeImages.length>2){
  return "xjImageContainer1"
}
}

return(
  <>
  <div className="xjtotWrapper">
  {windowWidth<360?displayGadgetToSmall():''}
  <div className={AreaChoice()}>

    {getBackGroundShade<1?<img src={getImageUrl} alt="Bakesoft" className="xjbackgroundImageRecepyNew" />:''}
 
  <div className="xjheaderArea">
    {getCountry!=='ja'?
  <div className={getBackGroundShade<1?"xjcompName":"xjcompName1"}>{title}</div>
  : <div className={getBackGroundShade<1?"xjcompNameJAP":"xjcompNameJAP1"}>{title}</div>}
  </div>

<div className="xjrangeArea">
     
      <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
        title={titleJusteraBakgrund} className="xjrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
       
       <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
        title={titleJusteraBakgrund} className="xjrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>

      </div>
      



        <div className="xjareaA">
    
            <div className="xjareaATop">
              
              <fieldset className="xjsearchFieldset">
                <legend className="xjsearchLegend">{searchLegend}</legend>
                <input type="text" className="xjsearchInput" placeholder={searchPlaceholdertext}
                onChange={(e)=>searchNameHandler(e.target.value)}
                value={getsearchName}
                
                />
                <button className="xjsearchButton" onClick={SearchRecipeHandler}
                onDoubleClick={clearSearchNameHandler}
                >{searchButton}</button>
              </fieldset>
  
   
                 <fieldset className="xjSelectRecepyFieldset">
                     <legend className="xjSelectRecepyLegend">{leftSelectLegendMinaRecept}</legend>
                     
                     <div className="SelectAreaTop">
                         <select className="xjSelectRecepySelect"
                          name="recepy" id="recepy" onChange={(e)=>categoryHandler(e.target.value)}>
                           <option defaultValue="0">&nbsp;&nbsp;{leftSelectFirstOption}&nbsp;!</option>
                         {getRecepyCategories.map((data,index)=>{
                             
                             return(
                                <option key={index} value={data.kategoriId}>&nbsp;&nbsp;{data.Benaemn}</option>
                             )
                         })}
                         </select>


                     </div>
     
                 </fieldset>
      <ShowRecepiesByCategory getRecepyBycategory={getRecepyBycategory} getSearchNew={getSearchNew} 
      showRecepyHandler={showRecepyHandler}
      />
      
             </div>
  
        
        
    


        </div>
        <div className="xjareaB">





        {showCommentStatus===true&&getResizeTextArea===false&&getMobileIsUsed===false?<ViewRecepyCommentFromAi getSearchNew={getSearchNew} getRecepyHeaderData={getRecepyHeaderData}
        recepyComments={recepyComments} getBackGroundShade={getBackGroundShade}  resetAiCommentHandler={resetAiCommentHandler}
        aiHeader={aiHeader}
        />:''}
      

      <div className="area-f">
    <div className="tabs">
      <button className={getTabNr!==1?"xjtab-button1":"xjtab-button2"} onClick={()=>showTab('info',1)}><CiImageOn  className="xjImageIcon"/><div className="xjIconText">{imageIconText}</div></button>
      <button className={getTabNr!==2?"xjtab-button1":"xjtab-button2"} onClick={()=>showTab('image',2)}><TbDeviceDesktopAnalytics className="xjRecipeIcon" /><div className="xjIconText">{recipeIconText}</div></button>
      <button className={getTabNr!==5?"xjtab-button1":"xjtab-button2"} onClick={()=>showTab('description',5)}><IoDocumentTextOutline className="xjrecipeDesc" /><div className="xjIconText">{descriptionIconText}</div></button>
      <button className={getTabNr!==3?"xjtab-button1":"xjtab-button2"} onClick={()=>showTab('upload',3)}><RiUploadCloud2Line className="xjimageIconUpload" /><div className="xjIconText">{uploadIconText}</div></button>
      <button className={getTabNr!==4?"xjtab-button1":"xjtab-button2"} onClick={()=>showTab('download',4)}><RiDownloadCloud2Line className="xjimageIconUpload" /><div className="xjIconText">{downloadIconText}</div></button>
 
    </div>

    <div id="info" className="tab-content">


<div className="xjImagesContainerWrapper">
<div className={ClassPicker()}>


{recipeImages.length > 0 ?
    Object.entries(recipeImages).map(([index, url]) => (
      <div  className="xjimagesListWrapper">
        <img key={index} src={url} alt={`Recipe ${index}`} className={getChosenImageIndex===index?"xjs3imagedata1":"xjs3imagedata"}
         onClick={()=>choseImageHandler(index)}
         onDoubleClick={()=>deleteImageHandlerContainer()}
         title={deleteImageTitle}
        />
        </div>
    ))
    :
    <div className="xjdataNotFoundWrapper">
    <img src={noimagefound1} alt="My Image" className="xjs3imagedataNotFound" />
    </div>
}
</div>
</div>
</div>

<div id="upload" className="tab-content">
 <UploadImage1 handleFileChange={handleFileChange} uploadImages={uploadImages}
 error={error} file={file}  chosenRecepyId={chosenRecepyId}
   choseImageButtontext={choseImageButtontext} choseImageUploadText={choseImageUploadText}
   recepyMustBeChosenBeforeUploadMessage={recepyMustBeChosenBeforeUploadMessage}
 />
 
    </div>
    
  <div id="download" className="tab-content">
        <div className="xjgenerateImageTop">
      <div className="xjgenerateImageWrapper">
      <textarea className="xjimageinput" placeholder={createImageTextPlaceholder} 
      value={getImageText} onChange={(e)=>setImageTextHandler(e.target.value)} />
     
      <button className={!getImageText?"xjgenerateImageButton":"xjgenerateImageButton1"} onClick={GenerateaiImage}
          disabled={!getImageText}
         >{createImageButtonText}</button>
   </div>
  
      </div>
    <div className="xjimageInformationTop">
      <div className="xjimageInformationText">
    {generateAIimageDisclaimer}
      </div>
    </div>
  </div>
  
    
    <div id="image" className="tab-content">
    

            
        <div className={getCountry!=='ja'?"xjrecepyHeaderContainer":"xjrecepyHeaderContainerJAP"}>
            <RecepyHeader getSearchNew={getSearchNew} chosenRecepyName={chosenRecepyName}
          ChangeRecepyNameHandler={ChangeRecepyNameHandler} getRecepyHeaderData={getRecepyHeaderData}
          getShowFurigana={getShowFurigana} setButtonSaveActive={setButtonSaveActive}
          setFuriganaNameHandler={setFuriganaNameHandler} getButtonSaveActive={getButtonSaveActive}
          getFuriganaName={getFuriganaName} getMobileIsUsed={getMobileIsUsed} getIpadIsUsed={getIpadIsUsed}
          getCountry={getCountry} setShowFurigana={setShowFurigana}
          />
        </div>
           
            {chosenRecepyId>0?<GetAiMessage getSearchNew={getSearchNew} RecepyData={RecepyData} expandedRow={expandedRow}
             IngredData={IngredData} handleRowClickIndex={handleRowClickIndex} chosenIngredid={chosenIngredid}
             getReceivedDataStatus={getReceivedDataStatus} deleteIngredientHandler={deleteIngredientHandler}
              addDbOwnerIngredient={addDbOwnerIngredient}
              changeAmountHandler={changeAmountHandler} getFoodInfoContainer={GetFoodInfoContainer}
               ingredCommentsHandler={ingredCommentsHandler} getnutritionData={getnutritionData}
               chosenRecepyId={chosenRecepyId} saveRecepyHandler={SaveRecepyHandler}
               HandleArbOrderClick={HandleArbOrderClick} getButtonSaveActive={getButtonSaveActive}
               SortBenaemnHandler={SortBenaemnHandler} SortOrderHandler={SortOrderHandler}
               SortKalories={SortKalories} getPortions={getPortions} sizeOfPortionHandler={sizeOfPortionHandler}
               NumPortionHandler={NumPortionHandler} getCalculatedPortion={getCalculatedPortion}
                getPortionSize={getPortionSize} getRecepyHeaderData={getRecepyHeaderData}
                receptSizeHandler={receptSizeHandler} getSliderValue={getSliderValue}
                getMobileIsUsed={getMobileIsUsed} listHeaderId={listHeaderId} listHeaderIngrediens={listHeaderIngrediens}
                 listHeaderMangd={listHeaderMangd} listHeaderKostnad={listHeaderKostnad} listHeaderKal= {listHeaderKal}
                 listHeaderOrder={listHeaderOrder}  psize={psize}
                 titleDatabasensIngrediensId={titleDatabasensIngrediensId}
                 titleKlickaforattSortera={titleKlickaforattSortera}
                 titleMangdIgramPerIngrediens={titleMangdIgramPerIngrediens}
                 titleKostnadPerIngrediens={titleKostnadPerIngrediens}
                 titleKalorierPerIngrediens={titleKalorierPerIngrediens}
                 ingredCommentBoxInfo={ingredCommentBoxInfo}
                ingredInfoBoxPlus={ingredInfoBoxPlus}
                ingredInfoBoxMinus={ingredInfoBoxMinus}
                titleMinskaMangd={titleMinskaMangd}
                titleOkaMangd={titleOkaMangd}
                antalTeckenInfoText={antalTeckenInfoText}
                titleHamtaNaringsvarde={titleHamtaNaringsvarde}
                hideNutrientValues={hideNutrientValues}
                nutrientListHeaderSubject={nutrientListHeaderSubject}
                nutrientListHeaderAmount={nutrientListHeaderAmount}
                nutrientListHeaderValue={nutrientListHeaderValue}
                
                titleKlickaForMerInformation={titleKlickaForMerInformation}
                titleDeleteFromRecipe={titleDeleteFromRecipe}
                footerTotalt={footerTotalt}
                titleTotalSummary={titleTotalSummary}
                addIngredientSelectFirstOption={addIngredientSelectFirstOption}
                numPortionsSelectlegendMobile={numPortionsSelectlegendMobile}
                numPortionSelectLegend={numPortionSelectLegend}
                portionSizeInputLegendMobile={portionSizeInputLegendMobile}
                portionSizeInputLegend={portionSizeInputLegend}
                portionInputText={portionInputText}
                sizeRecepyDoublerLegend={sizeRecepyDoublerLegend}
                titleDoublerLegend={titleDoublerLegend}
                addIngredientSelectLegend={addIngredientSelectLegend}
                saveRecepyButtonTextMobile={saveRecepyButtonTextMobile}
                saveRecepyButtonText={saveRecepyButtonText}
                getIpadIsUsed={getIpadIsUsed}
                rangeText={rangeText}
            
nutrientSummary={nutrientSummary}   
titleSummaEnergiIRecept={titleSummaEnergiIRecept}
titleTotalMangdProteinIRecept={titleTotalMangdProteinIRecept}
titleTotalMangdKolhydraterIRecept={titleTotalMangdKolhydraterIRecept}
titleTotalMangdFettIRecept={titleTotalMangdFettIRecept}
nutrientEnergyLegend={nutrientEnergyLegend}
nutrientProteinLegend={nutrientProteinLegend}
nutrientKolhydraterLegend={nutrientKolhydraterLegend}
nutrientFettLegend={nutrientFettLegend}
titleTaBortRecept={titleTaBortRecept}
deleteRecepyHandlerContainer1={deleteRecepyHandlerContainer1}
            
             />:''}

{/* {chosenRecepyId>0?<ViewNutritionSummary nutrientSummary={nutrientSummary} getSearchNew={getSearchNew} 
getCalculatedPortion={getCalculatedPortion}
titleSummaEnergiIRecept={titleSummaEnergiIRecept}
titleTotalMangdProteinIRecept={titleTotalMangdProteinIRecept}
titleTotalMangdKolhydraterIRecept={titleTotalMangdKolhydraterIRecept}
titleTotalMangdFettIRecept={titleTotalMangdFettIRecept}
nutrientEnergyLegend={nutrientEnergyLegend}
nutrientProteinLegend={nutrientProteinLegend}
nutrientKolhydraterLegend={nutrientKolhydraterLegend}
nutrientFettLegend={nutrientFettLegend}
/>:''} */}


{/* {chosenRecepyId>0?<BsTrash className={getSearchNew.deleteRecepy} 
title={titleTaBortRecept} onClick={()=>deleteRecepyHandlerContainer()}/>:''} */}
        
</div>
<div id="description" className="tab-content">
<ViewDescription getSearchNew={getSearchNew} getResizeTextArea={getResizeTextArea}
               getRecepyHeaderData={getRecepyHeaderData} descriptionHandler={descriptionHandler}
               rightBottomAntalTecken={rightBottomAntalTecken} translateInfoHandler={TranslateInfoHandler}
               
               />
</div>
  
</div>

        </div>
        <div className="xjareaC">
          {getOpenImageOnCSection===true?
          <div className="xjImagesContainerWrapper">
          <div className="xjImageContainer">
          
          
          {recipeImages.length > 0 ?
              Object.entries(recipeImages).map(([index, url]) => (
                <div className="xjimagesListWrapper">
                  <img key={index} src={url} alt={`Recipe ${index}`} className={getChosenImageIndex===index?"xjs3imagedata1":"xjs3imagedata"}
                   onClick={()=>choseImageHandler(index)}
                   onDoubleClick={()=>deleteImageHandlerContainer()}
                   title={deleteImageTitle}
                  />
                  </div>
              ))
              :
              <div className="xjdataNotFoundWrapper">
              <img src={noimagefound1} alt="My Image" className="xjs3imagedataNotFound" />
              </div>
          }
          </div>
          </div>:
          <ViewDescription getSearchNew={getSearchNew} getResizeTextArea={getResizeTextArea}
          getRecepyHeaderData={getRecepyHeaderData} descriptionHandler={descriptionHandler}
          rightBottomAntalTecken={rightBottomAntalTecken} translateInfoHandler={TranslateInfoHandler}
          
          />}
        {/* <div className="xjareaCTop">
          <RecepyDescriptionHeader getSearchNew={getSearchNew} getResizeTextArea={getResizeTextArea}
          setResizeTextAreaHandler={setResizeTextAreaHandler} getMobileIsUsed={getMobileIsUsed} rightHeaderBeskrivning={rightHeaderBeskrivning}
          chosenRecepyName={chosenRecepyName} chosenRecepyId={chosenRecepyId}/>
        <ViewDescription getSearchNew={getSearchNew} getResizeTextArea={getResizeTextArea}
               getRecepyHeaderData={getRecepyHeaderData} descriptionHandler={descriptionHandler}
               rightBottomAntalTecken={rightBottomAntalTecken} translateInfoHandler={TranslateInfoHandler}
               
               />
    



        </div> */}


        </div>
        <div className="xjsearchSpinnerContainer">
        <div className="xjsearchSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={getSpinnerLoading}/>
        </div>
        </div>
           {/* this alert is for the error messages*/}
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}

           {/* this alert is for the deltion of images */}
        {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
         btnYes={btnYes} btnNo={btnNo}
        />:''}
        {/* this alert is for the deltion of the recipe yes no question alert */}
        {getShowYesNoDeleteRecipeAlert===true?<YesNoAlertDeleteReceipe message={getYesNoDeleteRecipe}  
        onCloseYes={deleteRecepyHandler} onCloseNo={handleCloseAlertNoImage}  btnYes={btnYes} btnNo={btnNo} />:''}

        {/* this alert is for the confirmation of the recipe deleted */}
          {getShowAlert1===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert1}/>:''}

    </div>
 </div>
  
    </>
)
}

function ShowRecepiesByCategory(props){
    let {getRecepyBycategory,getSearchNew,showRecepyHandler}=props

    return(
    <div className="xjshowRecepiesTop">

     {getRecepyBycategory.map((data,index)=>
     <div key={data.receptid}>
  <button className={index % 2 === 0 ? "xjrecepiesByCategorySelectButton" : "xjrecepiesByCategorySelectButton2"}
  key={data.receptid}   
  value={data.receptid} 
  onClick={() => showRecepyHandler(data.receptid)}
>
  <div className="xjbuttonContent">
    {data.benaemn}
    {data.image_count > 0 && <IoIosImages className="xjbuttonIcon" />}
  </div>
</button>
        </div>
     )}
   
       </div>

   
    )

}


function GetAiMessage(props){
  let{getSearchNew,
    RecepyData,IngredData,
    ingredCommentsHandler,expandedRow,handleRowClickIndex,chosenIngredid,
    deleteIngredientHandler,getFoodInfoContainer,
    addDbOwnerIngredient,changeAmountHandler,saveRecepyHandler,
   getReceivedDataStatus,getnutritionData,chosenRecepyId,HandleArbOrderClick,
   getButtonSaveActive,SortBenaemnHandler,SortOrderHandler,SortKalories,getPortions,
   sizeOfPortionHandler,NumPortionHandler,getCalculatedPortion,getPortionSize,getRecepyHeaderData,getSliderValue,
   receptSizeHandler,psize,getMobileIsUsed,listHeaderId,listHeaderIngrediens,
   listHeaderMangd,listHeaderKostnad,listHeaderKal,listHeaderOrder,
   titleDatabasensIngrediensId,titleKlickaforattSortera,titleMangdIgramPerIngrediens,titleKostnadPerIngrediens,
   titleKalorierPerIngrediens,getIpadIsUsed,
  ingredCommentBoxInfo,ingredInfoBoxPlus,ingredInfoBoxMinus,titleMinskaMangd,titleOkaMangd,
  antalTeckenInfoText,titleHamtaNaringsvarde,hideNutrientValues,
  nutrientListHeaderSubject,nutrientListHeaderAmount,nutrientListHeaderValue,
  titleKlickaForMerInformation,titleDeleteFromRecipe,footerTotalt,titleTotalSummary,addIngredientSelectFirstOption,
  numPortionsSelectlegendMobile,numPortionSelectLegend,portionSizeInputLegendMobile,portionSizeInputLegend,
  portionInputText,sizeRecepyDoublerLegend,titleDoublerLegend,addIngredientSelectLegend,
  saveRecepyButtonText,saveRecepyButtonTextMobile,rangeText,

  nutrientSummary,            
titleSummaEnergiIRecept,
titleTotalMangdProteinIRecept,
titleTotalMangdKolhydraterIRecept,
titleTotalMangdFettIRecept,
nutrientEnergyLegend,
nutrientProteinLegend,
nutrientKolhydraterLegend,
nutrientFettLegend,
titleTaBortRecept,
deleteRecepyHandlerContainer1,
  





    }=props

  //so we need to check if the value is a number
  let totalCost=RecepyData.reduce((a,b)=>a+b.ADsum,0)
  //if the totalcost is undefined we will set it to 0
  if(isNaN(totalCost)){
    totalCost=0
  }else{
  totalCost=totalCost.toFixed(2)||0
  }

// Summarize the total kaloriekoeff * mangd
let totalsum = RecepyData.reduce((total, item) => total + (item.kaloriekoeff * item.mangd), 0);

// Check if the total is NaN and set it to 0 if it is
if (isNaN(totalsum)) {
totalsum = 0;
} else {
totalsum = totalsum.toFixed(2);
}


  let totalAmount=RecepyData.reduce((a,b)=>a+b.mangd,0)
  // if the total amount is undefined we will set it to 0
  if(isNaN(totalAmount)){
    totalAmount=0
  }
  
  if(chosenRecepyId===0){
    totalAmount=0
    totalCost=0
    totalsum=0
  }

if(getCalculatedPortion==Infinity){
getCalculatedPortion=1
}

let portionData = (getRecepyHeaderData && getRecepyHeaderData[0] && getRecepyHeaderData[0].portions) || 0;

if (portionData === undefined || portionData === null||portionData==Infinity||portionData===NaN) {
portionData =0;
}

let portionSize=(getRecepyHeaderData && getRecepyHeaderData[0] && getRecepyHeaderData[0].portionSize) || 0;
portionSize=parseFloat(portionSize)
if(portionSize===undefined||portionSize===null||portionSize==Infinity){
portionSize=-1


}


//kalla
  return(
      <div>
      <div className="xjheader">
        <div className="xjheaderContainer">
        <button className="xjheaderButtonBenaemn" title={titleKlickaforattSortera} onClick={()=>SortBenaemnHandler()}>{listHeaderIngrediens}</button>
        <button className="xjheaderButtonAmount" title={titleMangdIgramPerIngrediens}>{listHeaderMangd}</button>
        <button className="xjheaderButton" title={titleKostnadPerIngrediens}>{listHeaderKostnad}</button>
        <button className="xjheaderKcals" title={titleKalorierPerIngrediens} onClick={()=>SortKalories()}>{listHeaderKal}</button>
        <button className="xjheaderOrder" title={titleKlickaforattSortera} onClick={()=>SortOrderHandler()}>{listHeaderOrder}</button>
   
       
       
   </div>
        
  </div>
<div className="xjreceivedTop">
  <div className="xjreceivedContainer">

 {chosenRecepyId>0?RecepyData.map((data,index)=>

<div key={index+100}>
  
 <button className={index%2===0?"xjinfobuttonBenaemn":"xjinfobuttonBenaemn2"} 

onClick={()=>handleRowClickIndex(index,data.ingredid)}>{String(data.benaemn).substring(0,30)}{data.btext?<AiOutlineInfoCircle style={{fontSize: '13px',color:'blue' ,cursor:'pointer',marginLeft:'22px'}} title={titleKlickaForMerInformation}/>:''}</button>
<button className={index%2===0?"xjinfobuttonAmount":"xjinfobuttonAmount2"}>{data.mangd*psize ||0}</button>
<button className={index%2===0?"xjinfoButton":"xjinfoButton2"}>{String(data.ADsum*psize).substring(0,4)||0}</button>
<button className={index%2===0?"xjkcalsButton":"xjkcalsButton2"}>
  {Math.floor(((data.kaloriekoeff*data.mangd)*psize || 0) * 10) / 10}
</button>
<button className={index%2===0?"xjarborderButton":"xjarborderButton2"} onClick={()=>HandleArbOrderClick(index)}>{String(data.arborder||0)}</button>
&nbsp;<RiDeleteBackLine onClick={()=>deleteIngredientHandler(data.ingredid)} className="xjdeletebackline"
                   title={titleDeleteFromRecipe}/>
    
           

{/* //om vi har ett ingredid och vi ska expandera raden för att visa kommentar */}

{data.ingredid>0&&expandedRow === index && (<div className={getIpadIsUsed===false?getReceivedDataStatus===false?"xjanimateddiv50":"xjanimateddiv5":getReceivedDataStatus===false?"xjanimateddiv60":"animateddiv5"}>
  

<IngredComments getSearchNew={getSearchNew} RecepyData={RecepyData}
ingredCommentsHandler={ingredCommentsHandler} chosenIngredid={chosenIngredid} 
changeAmountHandler={changeAmountHandler} getFoodInfoContainer={getFoodInfoContainer}
getReceivedDataStatus={getReceivedDataStatus} getnutritionData={getnutritionData}
ingredCommentBoxInfo={ingredCommentBoxInfo}
ingredInfoBoxPlus={ingredInfoBoxPlus}
ingredInfoBoxMinus={ingredInfoBoxMinus}
titleMinskaMangd={titleMinskaMangd}
titleOkaMangd={titleOkaMangd}
antalTeckenInfoText={antalTeckenInfoText}
titleHamtaNaringsvarde={titleHamtaNaringsvarde}
hideNutrientValues={hideNutrientValues}
nutrientListHeaderSubject={nutrientListHeaderSubject}
nutrientListHeaderAmount={nutrientListHeaderAmount}
nutrientListHeaderValue={nutrientListHeaderValue}
/></div>)}
</div>
):''}


<div className="xjtotalCostContainer">
<button className="xjtotalcostBenaemn">{footerTotalt}: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  {getMobileIsUsed === true ? 
    `${(totalsum * psize / totalAmount * psize).toFixed(2)}/${parseInt((totalsum / totalAmount) * portionSize)}` 
    : ''
  }
</button>

<button className="xjtotalamountButton">{totalAmount*psize}</button>
<button className="xjtotalcostButton">{String(totalCost*psize).substring(0,6)}</button>
<button className="xjtotalcaloriesButton">{String((totalsum*psize)).substring(0,5)}</button>
{getMobileIsUsed===false?
<button className="xjavgcaloriesButton" title={titleTotalSummary}>{String(totalsum*psize/totalAmount*psize).substring(0,3)}/{parseInt((totalsum/totalAmount)*portionSize)}</button>
:''}
</div>
<div className="xjselectnewingredientContainer">
 <fieldset className="xjselectnewingredientFieldset">
  <legend className="xjselectnewingredientLegend">{addIngredientSelectLegend}</legend>
<select className="xjselectdbOwnerIngredient" onChange={(e)=>addDbOwnerIngredient(e.target.value)}>
<option defaultValue="0" hidden>{addIngredientSelectFirstOption}&nbsp;!&nbsp;</option>
         {IngredData.map((data,key)=><option key={key}value={data.ingredid}>&nbsp;&nbsp;{data.benaemn}</option>)}
  </select></fieldset>

  <div className="xjnumPortionsContainer">
<fieldset className="xjnumPortionsFieldset"><legend className="xjnumPortionsLegend">
  {getMobileIsUsed===true?numPortionsSelectlegendMobile:numPortionSelectLegend}
  </legend>

  <select  className="xjselectPortions"  value={portionData>0?portionData:getCalculatedPortion} onChange={(e)=>NumPortionHandler(e.target.value)}>
<option value={portionData>0?portionData:getCalculatedPortion} selected>&nbsp;&nbsp;{portionData>0?portionData:getCalculatedPortion}</option>
         {getPortions.map((data,key)=><option key={key}value={data.portion}>&nbsp;&nbsp;{data.portion}</option>)}
  </select> </fieldset>
  </div>



<fieldset className="xjsizeportionsIngramsFieldset"><legend className="xjsizeportionsIngramsLegend">
  {getMobileIsUsed===true?portionSizeInputLegendMobile:portionSizeInputLegend}
  </legend>
<input type="text" className="xjsizeportionsIngramsInput" placeholder={portionInputText}
onChange={(e)=>sizeOfPortionHandler(e.target.value)} value={parseInt(portionSize) ||''}/>
</fieldset>


{chosenRecepyId>0?<SaveButton getSearchNew={getSearchNew} saveRecepyHandler={saveRecepyHandler}
  getButtonSaveActive={getButtonSaveActive} mobileIsUsed={getMobileIsUsed}
  saveRecepyButtonText={saveRecepyButtonText}
  saveRecepyButtonTextMobile={saveRecepyButtonTextMobile}
  />:''}
</div>
      <fieldset className="xjreceptSizeFieldset">
        <legend className="xjreceptSizeLegend">{sizeRecepyDoublerLegend}</legend>
      <input
      type="range"
      min="-2"
      max="5"
      value={getSliderValue||1}
      onChange={(e)=>receptSizeHandler(e.target.value)}
      step="1"
      className="custom-slider"
      title={`${titleDoublerLegend} x ${getSliderValue}`}
      id={1}
    />

<div className="xjrangeGrid">
  {[...Array(8).keys()].map((value) => {
    let displayValue;
    switch (value) {
      case 0:
        displayValue = "/4";
        break;
      case 1:
        displayValue = "/2";
        break;
      case 2:
        displayValue = "0";
        break;
      default:
        displayValue = `x${value - 2}`;
    }
    return <span key={value}>{displayValue}</span>;
  })}
</div>



    {/* <div className="xjrangeGrid">
      {[...Array(8).keys()].map((value) => (
        <span key={value}>{`x${value -2}`}</span>
      ))}
    </div> */}







    </fieldset>

<div className="xjtrashcontainer">

    {chosenRecepyId>0?<BsTrash className="xjdeleteRecepy"
title={titleTaBortRecept} onClick={()=>deleteRecepyHandlerContainer1()}/>:''}
  </div>

  
    {chosenRecepyId>0?<ViewNutritionSummary nutrientSummary={nutrientSummary} getSearchNew={getSearchNew} 
getCalculatedPortion={getCalculatedPortion}
titleSummaEnergiIRecept={titleSummaEnergiIRecept}
titleTotalMangdProteinIRecept={titleTotalMangdProteinIRecept}
titleTotalMangdKolhydraterIRecept={titleTotalMangdKolhydraterIRecept}
titleTotalMangdFettIRecept={titleTotalMangdFettIRecept}
nutrientEnergyLegend={nutrientEnergyLegend}
nutrientProteinLegend={nutrientProteinLegend}
nutrientKolhydraterLegend={nutrientKolhydraterLegend}
nutrientFettLegend={nutrientFettLegend}

/>:''}




  </div>
</div>
</div>

)
}

function RecepyHeader(props){
  let {chosenRecepyName,ChangeRecepyNameHandler,getRecepyHeaderData,
  getShowFurigana,setFuriganaNameHandler,getFuriganaName,getButtonSaveActive,
  getMobileIsUsed,getIpadIsUsed,getCountry,setShowFurigana
  }=props

  // here we need to check if GetRecepyHeaderData.length is greater than 0
  // if it is we will show the name of the recepy from getRecepyHeaderData
  // if it is not we will show the name of the recepy from the state chosenRecepyName
  if(getRecepyHeaderData.length>0){
      chosenRecepyName=getRecepyHeaderData[0].benaemn
  }
let setClassName=''

// here we make a selection of the class name depending on the device
  if(getMobileIsUsed===true){
    setClassName='recepyHeaderJAPMobile'
  }else if(getIpadIsUsed===true){
    setClassName='recepyHeaderJAPIPAD'
  }
  else{
    setClassName='recepyHeaderJAP'
  }

  return(
      <>
<input type="text" className={getCountry!=='ja'?"xjrecepyHeader":"xjrecepyHeaderJ1"}
value={chosenRecepyName||''}
onChange={(e)=>ChangeRecepyNameHandler(e.target.value)}
onKeyPress={(e) => {
  if (e.key === 'Enter') {
    setShowFurigana(false);
  }
}}


/>

{getShowFurigana===true&&getCountry==='ja'?
<input type="text" className="xjrecepyHeaderJAP"
value={getFuriganaName}
onChange={(e)=>setFuriganaNameHandler(e.target.value)} 
onKeyPress={(e) => {
  if (e.key === 'Enter') {
    setShowFurigana(false);
  }
}}
placeholder='ふりがな、作成したデータを管理するために使います'/>
:''}
</>
)
}

function  ViewNutritionSummary(props){
  let {getSearchNew,nutrientSummary,getCalculatedPortion,
    titleSummaEnergiIRecept,titleTotalMangdProteinIRecept,titleTotalMangdKolhydraterIRecept,titleTotalMangdFettIRecept,
    nutrientEnergyLegend,nutrientProteinLegend,nutrientKolhydraterLegend,nutrientFettLegend
  }=props



  return(
      <div className="xjnutrientSummaryContainer">
          <div className="xjviewNutrientData">
          <BsFire className="xjfireIcon" />
          <button className="xjnutrientSummaryButtonKcals" title={titleSummaEnergiIRecept}>{nutrientEnergyLegend}:<br></br>{nutrientSummary.totalKcals>0?String(nutrientSummary.totalKcals/getCalculatedPortion).substring(0,5):''}</button>
          <LuWheat className="xjwheatIcon" />
          <button className="xjnutrientSummaryButtonCarb" title={titleTotalMangdKolhydraterIRecept}>{nutrientKolhydraterLegend}:<br></br>{nutrientSummary.totalCarbohydrates>0?String(nutrientSummary.totalCarbohydrates/getCalculatedPortion).substring(0,5):''}g&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
          <TbMeat className="xjmeatIcon" />
          <button className="xjnutrientSummaryButtonProtein" title={titleTotalMangdProteinIRecept}>{nutrientProteinLegend}:<br></br>{nutrientSummary.totalProtein>0?String(nutrientSummary.totalProtein/getCalculatedPortion).substring(0,5):''}g</button>
          <TbCheese className="xjcheeseIcon" />
          <button className="xjnutrientSummaryBFats" title={titleTotalMangdFettIRecept}>{nutrientFettLegend}:<br></br>{nutrientSummary.totalFat>0?String(nutrientSummary.totalFat/getCalculatedPortion).substring(0,5):''}g</button>
      </div></div>
  )
}
function ViewRecepyCommentFromAi(props){
  let {getSearchNew,recepyComments,getBackGroundShade,aiHeader,
  resetAiCommentHandler
  }=props

  return(
<div className="xjviewRecepyCommentFromAiContainer">
<div className={getBackGroundShade<1?"xjviewRecepyCommentFromAiHeader":"xjviewRecepyCommentFromAiHeader"}>{aiHeader}</div>
<fieldset className="xjviewRecepyCommentFromAiFieldset"><legend className={getSearchNew.viewRecepyCommentFromAiLegend}></legend>
  <button className="xjviewRecepyCommentFromAiButton"
  onClick={()=>resetAiCommentHandler()}
  >{recepyComments?recepyComments.content:''}</button>
</fieldset>
</div>
  )
}


function ImageSlider(props){
  let {getSearchNew,getImages,showImage,currentImageIndex}=props




  return (
    <div>
      {showImage && (
        <img
          src={`${getImages[currentImageIndex]}`}
          alt={`Image ${currentImageIndex + 1}`}
          className={getSearchNew.imageContainer}
       
        />
      )}
    </div>
  );
};

function RecepyDescriptionHeader(props){
  let {getSearchNew,chosenRecepyId,setResizeTextAreaHandler,getResizeTextArea,getMobileIsUsed,rightHeaderBeskrivning
  }=props
  return(
    <>

{getMobileIsUsed==false?
  <div className={getResizeTextArea===false?"xjrecepyDescriptionHeader":"xjrecepyDescriptionHeader1"} style={{display: 'flex', justifyContent: 'space-between'}}>
    <div>&nbsp;
      {chosenRecepyId>0?rightHeaderBeskrivning:''}
    </div>

    <div>
      <IoResizeOutline  className={getResizeTextArea===false?"xjresizeDescriptionText":"xjresizeDescriptionText1"} onClick={()=>setResizeTextAreaHandler()}/>
    </div>
  </div>
 : ''}
</>
  
  )
}

function IngredComments(props){
  let {getSearchNew,ingredCommentsHandler,RecepyData,chosenIngredid,
    changeAmountHandler,getFoodInfoContainer,getReceivedDataStatus,getnutritionData,
    ingredCommentBoxInfo,ingredInfoBoxPlus,ingredInfoBoxMinus,titleMinskaMangd,titleOkaMangd,
    antalTeckenInfoText,titleHamtaNaringsvarde,hideNutrientValues,
    nutrientListHeaderSubject,nutrientListHeaderAmount,nutrientListHeaderValue
  
  }=props
  const editor = React.useRef(null)

//here i need to get the comment for the ingredient¨
let myNewObjArr=[...RecepyData]
let commentIndex=myNewObjArr.findIndex(data=>data.ingredid==chosenIngredid)
let comment = typeof myNewObjArr[commentIndex].btext === 'string' ? myNewObjArr[commentIndex].btext : '';

// let comment=myNewObjArr[commentIndex].btext || ''
// i want to know the length of the text here
let textLength=comment.length||0
let textmax=200



  return(
    <>
    <div className="xjtextareaContainer">
      <textarea className="xjtextareaComments" rows="5" cols="50" placeholder={ingredCommentBoxInfo}
       onChange={(e)=>ingredCommentsHandler(e.target.value)} value={comment}/> 

           
        <AiOutlinePlusCircle 
        className="xjAiOutlinePlusCircle" 
        onClick={()=>changeAmountHandler(2)}
        onDoubleClick={()=>changeAmountHandler(4)}
        title={titleOkaMangd} 
        /><br></br>

        <AiOutlineMinusCircle 
        className="xjAiOutlinePlusCircle" 
        onClick={()=>changeAmountHandler(1)}
        onDoubleClick={()=>changeAmountHandler(3)}
        title={titleMinskaMangd} 
        />
        
        
      
        {getReceivedDataStatus===false?<button className="xjbuttonFoodInfo"
        onClick={getFoodInfoContainer}>{titleHamtaNaringsvarde}</button>:<button className="xjbuttonFoodInfo" onClick={getFoodInfoContainer}>{hideNutrientValues}</button>}
      <br></br><br></br><div className="xjnumTecken">&nbsp;&nbsp;{antalTeckenInfoText}:&nbsp;{textLength}/{textmax}
    </div>
    
   
     </div>
     {getReceivedDataStatus===true?<div className="xjfoodareaContainer">
      <div className="xjfoodAreaHeader">
    
      <button className="xjheaderButtonNutrient">{nutrientListHeaderSubject}</button>
      <button className="xjheaderButtonQuantity">{nutrientListHeaderAmount}</button>
      <button className="xjheaderButtonUnit">{nutrientListHeaderValue}</button>
      </div>


       <div className="xjfoodArea">
        {getnutritionData.map((data,index)=>(
          <div key={index+100} className="xjfoodarearows">
          <button className={index%2===0?"xjnutrientValue":"xjnutrientValue2"}>{data.nutrient}</button>
          <button className={index%2===0?"xjnutrientQuantity":"xjnutrientQuantity2"}>{data.quantity}</button>
          <button className={index%2===0?"xjnutrientUnit":"xjnutrientUnit2"}>{data.unit}</button>
          </div>
          
        ))}
        
        
     </div>
      </div>:''}
 
      </>
  )
}

function ViewDescription(props){
  let {getSearchNew,getRecepyHeaderData,descriptionHandler,getResizeTextArea,rightBottomAntalTecken,translateInfoHandler
  }=props
  let textmax=2500
  let textLength=0
  //here we need to check if GetRecepyHeaderData is undefined
  if (getRecepyHeaderData.length > 0) {
    if (getRecepyHeaderData[0].btext) {
        textLength = getRecepyHeaderData[0].btext.length || 0;
    } else {
        // Handle the case where btext is undefined or falsy
        textLength = 0; // or provide a default value
    }
} else {
    // Handle the case where getRecepyHeaderData is an empty array
    textLength = 0; // or provide a default value
}


  return(
    <div>
      <textarea className={getResizeTextArea===false?"xjviewdescription":"xjviewdescription1"}
       value={getRecepyHeaderData.length>0?getRecepyHeaderData[0].btext:''} onChange={(e)=>descriptionHandler(e.target.value)}/>
       <div className="numTeckenTop">
        {/* <div className={getResizeTextArea===false?"xjnumTecken1":"xjnumTecken2"}>{rightBottomAntalTecken}:&nbsp;{textLength}/{textmax} </div> */}
        {/* <button onClick={()=>translateInfoHandler()}>Translate this information to English</button>
    </div> */}
    </div></div>

  )
}

function SaveButton(props){
  let {getSearchNew,saveRecepyHandler,getButtonSaveActive,mobileIsUsed,saveRecepyButtonText,saveRecepyButtonTextMobile
  }=props
  return(
    <div>
      <div className="xjsaverecepybuttoncontainer">
      <button className={getButtonSaveActive===false?"xjsaveRecepyButton":"xjsaveRecepyButtonActive"}
       onClick={saveRecepyHandler}>
        {mobileIsUsed===true?saveRecepyButtonTextMobile:saveRecepyButtonText}</button>
    </div>
    </div>
  )
}

function UploadImage1(props) {
  let {handleFileChange,uploadImages,error,file,chosenRecepyId,choseImageButtontext,
  choseImageUploadText,recepyMustBeChosenBeforeUploadMessage
  } = props;


  return (
  <>
  <div className="xjimageUploadContainer">
  <div className="xjimageUploadWrapper">
      <input type="file" onChange={handleFileChange} id="file"
      accept=".png, .jpg, .jpeg" 
      className="xjinputimageUpload"
      />
        <label htmlFor="file" className="xjfileLabel">{choseImageButtontext}</label>
      {error && <div className="xjerror">{error}</div>}
      {file && <div className="xjfileName">{file.name}</div>}

      </div>
    
      <div className="xjbuttonUploadImageWrapper">
      <button onClick={uploadImages} className={error||!file||!chosenRecepyId?"xjbuttonUploadImage":"xjbuttonUploadImage1"}
      disabled={error||!file||!chosenRecepyId}>{choseImageUploadText}</button>
    </div></div>
    <div className="xjimageUploadMessage">{!chosenRecepyId?recepyMustBeChosenBeforeUploadMessage:''}</div>
    </>
  );
}

export{SearchRecepyNew2,ShowRecepiesByCategory,ViewNutritionSummary,ViewRecepyCommentFromAi,GetAiMessage,
  ImageSlider,RecepyDescriptionHeader,IngredComments,ViewDescription,SaveButton,RecepyHeader,UploadImage1}


