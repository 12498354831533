import React from 'react';
import './info.css';
import { ArrowRight, Facebook,CheckCircle2, Mail } from "lucide-react";
export default function ContactInfo(props){
    let{getImageUrl,buttonId,contactInformationText
    }=props;
  
    return(
<div className="infoContactInfoTop">
              <div className="integrityPolicyBackgroundImage" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="faqtransparentBox">
  
         <div className="infoContactInfoHeader">{contactInformationText}</div>
               <div className="infoContactInfoWrapper">
                 
                           <div className="infoContactInfoText">Hellström Trading i Göteborg AB</div>
                            <div className="infoContactInfoText">Skenevägen 4</div>
                            <div className="infoContactInfoText">512 60 Överlida</div>
                            <div className="infoContactInfoText">Sweden</div>
                            <div className="infoContactInfoText">Org.nr:556572-0413</div>
                            <div className="infoContactInfoText">
                               
                                <div className="infoContactEmailWrapper">
                                    <div className="infoContactEmailIcon">
                                       <Mail size={28} />
                                    </div>

                                    <div  className="infoContactEmailLink">
                                         <a href="mailto:support@bakesoft.se"
                                         className="infoContactEmailLink"
                                         >support@bakesoft.se</a>
                                    </div>
  
                                </div>
                            </div>
              
                </div>
        </div>
    </div>
</div>
  
    )
  }