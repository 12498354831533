import { Utensils, BookOpen, Lightbulb, Image, Search, Scale } from 'lucide-react';
import '../info.css'
export default function AboutUsSwedish(props){
    let {getImageUrl, buttonId} =props
  
  
    return (
      <div className="basicServicesWrapper">
        <div className="infoBEnglish" style={{ backgroundImage: `url(${getImageUrl(buttonId)})` }}>
    <div className="aboutUsTransparentBox">
  <div className="basicServicesHeader"></div>
  <div className="aboutUsSurroundBorder">
  <Utensils   className="aboutUsLeafIcon"/>
    <div className="aboutUsChapter">
  <div className="aboutUsServicesCommentText">
  Bakesoft.se har utvecklats gradvis genom flera prototyper av programvara som vi ursprungligen skapade för bageriet, Överlida Bageri, beläget i västra Sverige. Vi har tidigare drivit bageri i Göteborg sedan 90-talet.
</div>
</div>

<div className="aboutUsChapter">
<div className="aboutUsServicesCommentText">
Under arbetet med Överlida Bageri utvecklades den första versionen av Bakesoft.se med fokus på att förbättra kontrollen över marginaler, inköp, recept och produkter.
</div>
</div>

<div className="aboutUsChapter">
<div className="aboutUsServicesCommentText">
 Den första prototypen kunde hantera ingredienser och räkna ut receptkostnader, vilket gjorde det enklare att jämföra kostnader mellan olika recept beroende på valda ingredienser.
</div>
</div>

<div className="aboutUsChapter">
<div className="aboutUsServicesCommentText">
Genom åren har applikationen utvecklats, och under de senaste 12 månaderna har den vuxit kraftigt. I december 2023 fick applikationen ett webbaserat gränssnitt för mobiltelefoner, utöver den tidigare versionen för iPad. Idag stödjer vi de flesta typer av enheter, från mobiltelefoner till stationära datorer.
</div>
</div>

<div className="aboutUsChapter">
<div className="aboutUsServicesCommentText">
2023 och 2024 såg stora framsteg med flera AI-integrationer och ny funktionalitet, som förbättrade receptsökningar, bilduppladdning och AI-genererade bilder. 
</div>
</div>

<div className="aboutUsChapter">
<div className="aboutUsServicesCommentText">
Hösten 2023 beslutade vi oss för att göra Bakesoft.se tillgängligt för fler användare, vilket innebar omfattande ombyggnad av applikationen.
Vi har arbetar för att skapa en lösning för ingrediens-, recept- och produkthantering, eftersom vi hellre fokuserar på att baka och ha full kontroll över våra produkter än att sitta med manuella beräkningar. Det sparar oss tid och ger oss full överblick över vår verksamhet.
</div>
</div>
<BookOpen   className="aboutUsLeafIcon"/>
  </div>
  </div>
  </div>
  </div>

  
  
      )
  
  
  }